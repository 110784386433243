.custom-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: #fff;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
      0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    padding: 32px;
    border-radius: 5px;
    font-size: 14px;
    text-align: center;
  }
  .custom-modal svg {
    font-size: 4rem;
    color: #f15e5e;
  }
  .custom-modal .description {
    margin-top: 1rem;
  }
  .custom-modal .cancel-btn:hover,
  .custom-modal .cancel-btn {
    background-color: #4e555b;
  }
  