.cus-box-chat .payment-icon p {
    font-size: 12px !important;
    color: #494545 !important;
    margin-left: 5px;
    display: flex;
    align-items: center;
}

.customer-sender-delete .payment-icon span,
.customer-receiver-delete .payment-icon span {
    font-size: 16px !important;
    font-weight: 600 !important;
}

.customer-sender-delete .payment-icon,
.customer-receiver-delete .payment-icon {
    text-decoration: line-through;
}

.customer-sender-delete .payment-icon,
.customer-receiver-delete .payment-icon {
    display: flex;
    margin-right: 6px;
}

.customer-sender .payment-icon {
    color: #ff0000;
    margin-right: 6px;
    display: flex;
    align-items: center;
    margin-right: 6px;
}

.customer-receiver .payment-icon {
    color: #008000;
    display: flex;
    align-items: center;
}

.customer-sender .payment-icon span:nth-child(2),
.customer-receiver .payment-icon span:nth-child(2) {
    display: flex;
    align-items: center;
}

.customer-sender .payment-icon span:first-child,
.customer-receiver .payment-icon span:first-child {
    font-size: 18px !important;
    font-weight: 600;
}

.customer-sender .cus-count {
    margin: 4px !important;
    text-align: end;
    color: #494545;
    font-size: 14px;
}

.customer-sender-delete,
.customer-sender,
.supplier-sender-delete,
.supplier-sender {
    justify-content: end;
    display: grid;
}

.supplier-receiver-delete,
.supplier-receiver,
.customer-receiver-delete,
.customer-receiver {
    justify-content: flex-start;
    display: grid;
    right: 0 !important;
    left: 10px !important;
}

.cus-box-chat {
    padding: 2px !important;
    margin: 1px;
    border: 1px solid #ccc;
    border-radius: 4px;
    display: inline-block !important;
}

.cus-box-chat .description {
    padding: 0px 10px;
    font-size: 15px !important;
    text-transform: capitalize;
    font-weight: 500;
    display: flex;
    align-items: center;
}