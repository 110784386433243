.skeleton-chat-main {
    margin-top: 5rem;
}

.customer-sender-skeleton,
.customer-receiver-skeleton {
    margin-top: 1rem;
}

.customer-receiver-skeleton {
    justify-content: flex-start;
    display: grid;
    right: 0 !important;
    left: 10px !important;
}

.customer-sender-skeleton {
    justify-content: end;
    display: grid;
}

.skeleton-chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
}

.skeleton-chat-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 10px #6f757c33;
}