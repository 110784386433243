.payment-container .heading:nth-child(3) {
    font-weight: 500 !important;
}

.payment-container input {
    font-size: 1.4rem;
    border: none !important;
    width: 40%;
    outline: none;
}

.payment-input-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem 0 2rem 3.5rem;
    text-align: initial;
}

.payment-container .note-textarea {
    padding: 10px;
    color: #000;
    background: #f1ecec;
    text-align: center;
    font-size: 1rem;
    border: none !important;
    border-radius: 5px;
    font-weight: 500;
    outline: none;
}

.bill-input-filed {
    position: absolute;
    left: auto;
    right: 0px;
    opacity: 0;
}