.net-balance .css-83ijpv-MuiTypography-root {
  font-size: 18px !important;
  color: #008000 !important;
  font-weight: 600;
}

.net-balance-down .css-83ijpv-MuiTypography-root {
  font-size: 18px !important;
  color: #ff0000 !important;
  font-weight: 600;
}

.net-balance .css-10hburv-MuiTypography-root,
.net-balance-down .css-10hburv-MuiTypography-root {
  font-size: 14px !important;
}

.cus-box-chat .heading {
  font-size: 14px;
  margin-left: .5rem;
  font-weight: 500 !important;
}

.payment-wapper:last-child {
  margin-bottom: 2rem !important;
}

.account-statment-printview-header {
  background-color: #4caf50;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.account-statment-printview-header .heading,
.account-statment-printview-header .description {
  color: #fff !important;
}

.account-statement-table {
  width: 100%;
}

.account-statement-table .payment-date {
  border: 1px solid #ddd;
  border-radius: 100%;
  text-align: center;
  justify-content: center;
  margin: auto;
  padding: 2px 6px;
  width: 64px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.account-statement-table td {
  border-bottom: 1px solid #ddd;
  text-align: center;
  /* width: 100%; */
  padding-bottom: 10px !important;
  padding-top: 10px !important;
}

.account-statement-table tr {
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px !important;
}