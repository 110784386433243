.account-inners {
    margin-bottom: 0;
    margin-top: 10px;
    font-size: 0.9rem !important;
    line-height: 1.5 !important;
    letter-spacing: 0.02857em;
    text-decoration: none;
    color: rgb(123, 128, 154);
    font-weight: 400 !important;
}

.account-inners-main {
    margin-bottom: 0;
    margin-top: 10px;
    font-size: 1rem !important;
    font-weight: 700 !important;
    letter-spacing: 0.0075em !important;
    text-transform: capitalize;
    vertical-align: unset;
    text-decoration: none;
    color: rgb(52, 71, 103);
}

.account-inner-icon {
    position: absolute;
    right: 15px;
}

.chart-skeleton{
    transform: rotate(180deg);
}