.fotter-custom-main {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    margin: auto;
}

.custom-field-design {
    margin-left: 8px;
    flex: 1;
    font-size: 1.2rem;
    border: none;
    color: rgb(52, 71, 103);
    font-weight: 700;
}

.btn-custom {
    width: 100% !important;
    margin: 0px 20px !important;
    padding: 9px 22px !important;
    border-radius: 28px !important;
}

.fotter-custom {
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    padding: 16px 0px;
    position: fixed;
    bottom: 0;
    background-color: #fff;
    width: 100%;
}