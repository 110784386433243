.custom-policy {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background-color: #fff;
  margin: 20px 20px;
  padding: 20px;
  border-radius: 14px;
  
}
.privacy-cus-icon {
  border: 1px solid #d8a24a;
  padding: 14px 16px;
  border-radius: 50%;
  font-size: 28px !important;
  color: #d8a24a;
}
