.payment-date-card {
    background-color: #f0f4f5;
    border: 1.5px solid #d1d7db;
    padding: 3px;
    border-radius: 10px;
    color: #000;
    font-size: 13px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
}

.customer-statement-table tbody td {
    border: none !important;
}

.customer-statement-table tbody tr {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.custom-text {
    font-size: 14px !important;
    font-weight: bold !important;
}

.custom-text .css-10hburv-MuiTypography-root {
    font-size: 14px !important;
    font-weight: bold !important;
}

.customer-statement-table .payment-amt {
    color: green;
    text-align: left;
}

.customer-statement-table .credit-amt {
    color: red;
    text-align: right;
}

.amt-and-balance {
    padding: 1.5rem 0;
    text-align: center;
}

.cs-fliter-card {
    color: #000 !important;
    border-radius: 30px !important;
    background: #fff !important;
    border: 1px solid #ccc !important;
}

.cs-fliter-card.active {
    border: 1px solid green !important;
    background: #d2f6d2 !important;
}