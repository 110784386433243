.cus-bottomnavigation {
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0px 0px 10px #ccc;
  /* box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px; */
}

.cus-bottomnavigation a {
  color: #d8a24a !important;
  letter-spacing: 0px !important;
  font-weight: 500;
}