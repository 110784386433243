.verifyDiv {
    padding: 3rem 10px;
}

.otpElements {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    /* border: 1px solid transparent; */
    /* border-radius: 12px; */
    padding: 1rem 15px 0rem 15px;
}

.otp-resend-root span{
color: #e74c3c !important;
}

.resend-btn {
  color: #e74c3c;
  cursor: pointer;
  text-decoration: underline;
}

.verfile-acc {
    font-size: 1.4rem !important;
    font-weight: 700 !important;
    letter-spacing: 0.0075em !important;
    text-transform: capitalize;
    vertical-align: unset;
    text-decoration: none;
    color: rgb(52, 71, 103);
}

.verfile-acc p {
    font-size: 0.875rem !important;
    line-height: 1.5 !important;
    letter-spacing: 0.02857em;
    text-decoration: none;
    color: rgb(123, 128, 154);
    font-weight: 400 !important;
}

.otp-wapper {
  display: grid;
  align-items: center;
  justify-content: center;
}
.otp-wapper .description {
  margin-bottom: 1rem !important;
}
.otp-wapper div:nth-child(2) {
  align-items: center;
}
.resend-btn {
  color: #e74c3c;
  cursor: pointer;
  text-decoration: underline;
}

/* .otp {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  align-items: center;
} */

/* p {
  color: white;
  margin-bottom: 0px;
} */
/* .inputStyle {
  width: 50% !important;
  height: 45px;
  border-radius: 7px;
  border: 0px;
  margin-left: 8px;
  margin-right: 8px;
  background: #dddddd;
  font-size: 20px;
} */
/* .p3 {
  font-size: 14px;
} */
/* .resend {
  text-decoration: underline;
  font-size: 14px;
} */