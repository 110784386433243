.payment-detail-card {
    box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
    background-color: #fff;
    padding: 15px;
    border-bottom: 2px solid #eee;
}

.payment-detail-card.sub-card .heading:first-child {
    font-size: 19px !important;
    font-weight: 600 !important;
    color: #d8a24a;
}

.payment-detail-card .add-bill-card {
    display: inline-block;
    border: 2px solid #d8a24a;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    height: auto;
}

.payment-detail-card .bill-image {
    box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
    border: 1px solid #ccc;
}

.payment-detail-card.sub-card nav li div:first-child,
.payment-detail-card.delete-card nav li div:first-child {
    padding: 0px !important;
}

.payment-detail-card.sub-card .divider {
    height: 0px !important;
    background: #cccccc26;
    width: 75%;
    display: flex;
    margin: 10px auto;
}

.payment-detail-card.sub-card div nav li div span {
    font-weight: 600;
    color: #008000;
}

.payment-detail-card.delete-card nav li div span {
    font-weight: 600;
    color: #ff0000;
}