.business-card-1 {
    background-color: #515368;
    padding: 10px;
    width: 100%;
    overflow: hidden;
    text-align: center;
}

.business-card-1 .description:first-child {
    margin-top: 1rem;
    color: #fff;
    font-size: 14px !important;
}

.business-card-1 .heading {
    margin-top: 1.5rem !important;
}

.whatsapp-share-btn {
    border-radius: 30px !important;
    background-color: green !important;
}

/* business card 0 */
.business-card-0 {
    display: grid;
    overflow: hidden;
    position: relative;
    line-height: 1.6;
    width: 90% !important;
    margin: auto !important;
    padding: 10px;
    font-size: 12px !important;
    border-radius: 6px;
    box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.4);
}

.business-card-0 .heading,
.business-card-0 .description {
    position: relative;
    font-size: 12px !important;
    z-index: 2 !important;
}

.business-card-0 .description:first-child,
.business-card-0 .heading {
    text-align: right !important;
    font-size: 12px !important;
    color: #fff;
}

.business-card-0 .description:nth-child(4) {
    text-align: left !important;
    font-size: 12px !important;
    width: 150px;
}

.business-card-0 .background {
    z-index: 1;
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.business-card-0 .slice {
    top: -3rem;
    left: -0.5rem;
    position: absolute;
    width: 38rem;
    height: 13rem;
    transform: rotate(38deg);
}