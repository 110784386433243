/* ChatHeader */
.botton-top-cus {
    position: absolute !important;
    text-align: center !important;
    left: 0 !important;
    right: 0 !important;
    font-size: 5rem !important;
    margin: auto !important;
    color: #acacac !important;
    top: -1.8rem !important;
}

.display-custom {
    display: inline-grid !important;
    text-align: center !important;
    margin: 10px 0px;
}

.cus-icons {
    margin: 10px 30px !important;
}

.cus-icons:hover {
    background-color: #1976d2 !important;
}

.icon-btn-cus {
    color: #fff !important;
    font-size: 1.8rem !important;
}

.cus-chat-header {
    background-color: #fff !important;
    color: #000 !important;
    position: fixed !important;
    z-index: 1;
    box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px !important;
}

.chat-header {
    padding-left: 10px !important;
    padding-right: 0px !important;
    padding: 10px !important;
}

.chat-username {
    font-size: 1.1rem !important;
    font-weight: 600 !important;
    letter-spacing: 0.0075em !important;
    text-transform: capitalize;
    vertical-align: unset;
    text-decoration: none;
    color: rgb(52, 71, 103) !important;
}

.chat-username-info {
    font-size: 0.76rem !important;
    line-height: 1.5 !important;
    letter-spacing: 0.02857em !important;
    text-decoration: none;
    color: rgb(123, 128, 154);
    font-weight: 400 !important;
}

.cus-box-chat .payment-check {
    display: flex;
    align-items: center;
    padding-right: 5px;
}