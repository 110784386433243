.pin-container {
    margin: 1rem auto;
    text-align: center;
}
.inputStyle {
  padding: 0.5rem;
  margin: 0 1rem;
  text-align: center;
  border: 2px solid #ddd;
}
