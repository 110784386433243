/* AlertRating Css */
.custom-rating {
  background: #91c8ff8f;
  margin: auto !important;
  padding: 3px 14px;
  width: 85%;
  border-radius: 10px 0px 0px 10px;
  margin-bottom: 20px !important;
  border: 1px solid transparent;
}

.custom-rating {
  background: #91c8ff8f;
  margin: auto !important;
  padding: 3px 14px;
  width: 85%;
  border-radius: 10px 0px 0px 10px;
  margin-bottom: 20px !important;
  border: 1px solid transparent;
}

.custom-rating1 {
  background: #91c8ff8f !important;
  margin: 10px auto !important;
  padding: 3px 14px !important;
  width: 90% !important;
  border-radius: 10px !important;
  border: 1px solid transparent !important;
}

.custom-rating span,
.custom-inner-rating span {
  color: #1976d2 !important;
  font-size: 1.2rem !important;
}

.inner-chat-header {
  font-size: 1.2rem !important;
  font-weight: 700 !important;
  letter-spacing: 0.0075em !important;
  text-transform: capitalize;
  vertical-align: unset;
  text-decoration: none;
  color: rgb(52, 71, 103);
}

/* BalanceDate Css */
.balance-datebox {
  background: #cccccc54;
  margin: auto !important;
  padding: 3px 14px !important;
  width: 85% !important;
  border-radius: 10px;
  margin-bottom: 20px !important;
  border: 1px solid transparent;
}

.payment-check .css-i4bv87-MuiSvgIcon-root {
  font-size: 1rem !important;
}

.no-payment-container {
  text-align: center;
}

.add-bill-input {
  display: inline-block !important;
  font-size: 16px !important;
  font-weight: 600;
  border: 2px solid #1976d2 !important;
  border-radius: 10px !important;
  padding: 20px 10px !important;
}

.bill-img-container {
  width: 230px;
  height: 230px;
  overflow: hidden;
}

.bill-img-container img {
  width: 100%;
}

.preview-img-container img {
  width: 100px;
  height: 100px;
  box-shadow: 0px 0px 10px #ccc;
  border-radius: 10px;
  margin: 0px 15px;
}

/* supplier-sender  */

.supplier-sender .css-83ijpv-MuiTypography-root {
  font-size: 12px !important;
  color: #494545 !important;
}

.supplier-sender .payment-icon {
  color: #ff0000 !important;
  margin-right: 6px;
  display: flex;
}

.supplier-sender .cus-count {
  margin: 4px !important;
  text-align: end;
  color: #494545;
  font-size: 14px;
}

.supplier-receiver .payment-icon {
  color: #008000;
  display: flex;
}

.customer-sender .receiver-icon,
.supplier-sender .receiver-icon {
  transform: rotate(-90deg);
}

.customer-receiver .receiver-icon,
.supplier-receiver .receiver-icon {
  transform: rotate(90deg);
}

.customer-receiver .cus-count,
.supplier-receiver .cus-count {
  margin: 4px !important;
  text-align: start;
  color: #494545;
  font-size: 14px;
}

.cash-receipt-printview-header {
  width: 100%;
  text-align: left;
  padding: 20px;
}

.cash-receipt-printview-header .heading {
  margin-bottom: 0rem;
  font-size: 14px
}

.cash-receipt-printview-header .description {
  font-size: 14px;
  margin-top: 0rem;
  text-align: left;
}