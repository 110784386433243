.account-inners {
  margin-bottom: 0;
  margin-top: 10px;
  font-size: 0.9rem !important;
  line-height: 1.5 !important;
  letter-spacing: 0.02857em;
  text-decoration: none;
  color: rgb(123, 128, 154);
  font-weight: 400 !important;
}

.account-inners-main {
  margin-bottom: 0;
  margin-top: 10px;
  font-size: 1rem !important;
  font-weight: 700 !important;
  letter-spacing: 0.0075em !important;
  text-transform: capitalize;
  vertical-align: unset;
  text-decoration: none;
  color: rgb(52, 71, 103);
}

.account-inner-icon {
  position: absolute;
  right: 15px;
}

.photo-icon span {
  bottom: 0% !important;
  border-radius: 50% !important;
  top: auto !important;
  height: 40px !important;
  min-width: 40px !important;
}

.profile-subtitle {
  font-size: 1rem !important;
  line-height: 1.5 !important;
  letter-spacing: 0.02857em !important;
  text-decoration: none;
  color: rgb(123, 128, 154);
  font-weight: 400 !important;
}

.profile-title {
  font-size: 1.1rem !important;
  font-weight: 700 !important;
  letter-spacing: 0.0075em !important;
  text-transform: capitalize;
  vertical-align: unset;
  text-decoration: none;
  color: rgb(52, 71, 103) !important;
}

.setting-list-card li {
  background: #fff;
  text-decoration: none;
  border: 1px solid #00000033;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 10px;
  padding: 3px !important;
  margin-bottom: 1rem;
}

.setting-list-card span {
  font-size: 18px;
  font-weight: 600;
  color: #344767;
}

.setting-list-card svg {
  font-size: 2rem;
  color: #eab45e;
}