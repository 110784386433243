.cus-tabpanel {
  margin-bottom: 8.6rem !important;
  overflow-x: scroll;
  padding-top: 4rem !important;
}

.tab-main {
  /* position: fixed; */
  /* z-index: 1; */
  width: 100%;
  /* margin-top: 2.9rem !important; */
  background: #fff;
}

.add-cus-botton {
  padding: 10px 16px !important;
  /* background-color: #eab45e !important; */
}

.react-tel-input .form-control {
  width: 100% !important;
  padding-left: 48px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.cus-chat-header {
  background-color: #fff !important;
  color: #000 !important;
  position: fixed !important;
  z-index: 1;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px !important;
}

.chat-header {
  padding-left: 10px !important;
  padding-right: 0px !important;
  padding: 10px !important;
}

.inner-add-customer {
  font-size: 1rem !important;
  font-weight: 700 !important;
  letter-spacing: 0.0075em !important;
  text-transform: capitalize;
  vertical-align: unset;
  text-decoration: none;
  color: rgb(52, 71, 103);
}

.filter-inner {
  font-size: 1.2rem !important;
  font-weight: 700 !important;
  letter-spacing: 0.0075em !important;
  text-transform: capitalize;
  vertical-align: unset;
  text-decoration: none;
  color: rgb(52, 71, 103);
}

.tabs-cus-botton {
  right: 12px !important;
  justify-content: end !important;
  margin-top: 10px !important;
  position: absolute !important;
  width: 110px !important;
  font-size: 13px !important;
  border-radius: 25px !important;
  padding: 4px 18px !important;
}

.cus-tab-main {
  line-height: 1.5 !important;
  text-decoration: none !important;
  font-weight: 600 !important;
}

.close-cus {
  position: absolute;
  right: 11px;
  color: red;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  letter-spacing: 0.02857em !important;
  text-decoration: none !important;
  /* color: rgb(123, 128, 154) !important; */
  font-weight: 400 !important;
}

.filter-font-cus {
  font-size: 1rem !important;
  line-height: 1.5 !important;
  letter-spacing: 0.02857em !important;
  text-decoration: none !important;
  color: rgb(52, 71, 103) !important;
  font-weight: 400 !important;
}

.filter-info-cus {
  font-size: 1rem !important;
  line-height: 1.5 !important;
  letter-spacing: 0.02857em !important;
  text-decoration: none !important;
  color: rgb(123, 128, 154) !important;
  font-weight: 400 !important;
}

.toast-custom {
  position: bottom left !important;
}