.bill-preview-header {
  margin-top: 1rem;
  text-align: center;
  box-shadow: 0px 0px 10px #ccc;
  padding: 10px;
  background-color: #0000007a;
}

.bill-preview-header .heading {
  color: #fff;
  font-weight: 600;
}

.add-image-btn {
  background: #d7e0ff !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  border-radius: 30px !important;
  color: #000 !important;
  padding: 8px 20px !important;
}

.bill-img-container {
  width: 100%;
  overflow: hidden;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bill-img-container img {
  border-radius: 5px;
  box-shadow: 0px 0px 10px #ccc;
}