.custom-header-search {
  box-shadow: none !important;
  height: 78px !important;
  position: fixed !important;
  z-index: 1;
  background-color: #ffffff !important;
}

.custom-toolbar {
  margin-left: 15px;
  margin-right: 15px;
  top: 1rem;
  min-height: 46px !important;
  padding: 0px !important;
  background-color: #ffffff;
  border: 1px solid #00000088;
  border-radius: 30px;
}
